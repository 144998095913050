import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import { Styles } from "./styles/walkthrough.js";

export default class WalkThrough extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <Styles>
        {/* About Us */}
        <section className="about-us">
          <Container>
            <Row>
              <Col md="6">
                <iframe
                  className="main-video"
                  src="https://player.vimeo.com/video/678642077?h=4594724db1"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <div className="about-content">
                  <div className="row">
                    <div className="col-md-7">
                      <img
                        className="img-fluid"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/home/app-1.png"
                        }
                        alt="single-img-eighteen"
                      />
                    </div>
                    <div className="col-md-5 paddingtop-2">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Student Profile Updates.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Fees Payment Status.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">IA Marks.</span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Attendance status.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Faculty Feedback.
                          </span>
                        </li>
                        <li>
                          <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                          <span className="ttm-list-li-content">
                            Recent Notification and Events.
                          </span>
                        </li>
                      </ul>
                      <br />
                      <a
                        className="app-button"
                        href="https://drive.google.com/file/d/1JiU0F5piij_X_ay1a3z7yq2FoRhdf8Xz/view?usp=sharing"
                        target="_blank"
                      >
                        App download link 
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="about-content paddingtop-2">
                  <h4 className="about-title">
                    Experienced Professionals of BIT make your engineering
                    journey exciting and enjoyable.
                  </h4>
                  <p className="about-para justify">
                    At BIT, we rejoice a culture of experiential learning.
                    Faculty of BIT love to teach and set the highest standard of
                    excellence in their disciplines. We at BIT offer plenty
                    of resources for students, to make the concepts of science
                    and engineering easy to grasp and irresistibly interesting.
                    Our students work in close coordination with faculty,
                    tackle present industry challenges and translate ideas into
                    action in the pursuit of serving the society.
                  </p>
                  <p className="about-para justify">
                    To facilitate overall personality development of students,
                    we have more than 30 student activity clubs like XKalibre,
                    Shuttered, LEO, Sanskriti, Rotaract etc. which conduct
                    various technical, cultural and sports events. We have
                    Centre of Excellence in each Department and Incubation,
                    Startup, Idea Lab, Skill development and other supportive
                    and nurturing centers to emphasize designing, inventing,
                    collaborating, and translating students’ expertise to solve
                    human problems.
                  </p>
                </div>
              </Col>
              <Col md="6" className="about-content" style={{ margin: "auto" }}>
                <Row>
                  <Col sm="4">
                    <div className="counter-box box1 text-center">
                      <h3>
                        <CountUp end={44} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Existing since (Years)</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box2 text-center">
                      <h3>
                        <CountUp end={1979} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Established in</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box3 text-center">
                      <h3>
                        <CountUp end={5004} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Students on roll</p>
                    </div>
                  </Col>
                </Row>
                <Row className="paddingtop-2">
                  <Col sm="4">
                    <div className="counter-box box1 text-center">
                      <h3>
                        <CountUp end={163} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Companies visiting</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box2 text-center">
                      <h3>
                        <CountUp end={150} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>MOUs with Companies</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box3 text-center">
                      <h3>
                        <CountUp end={1142} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Placements 2022</p>
                    </div>
                  </Col>
                </Row>
                <Row className="paddingtop-2">
                  <Col sm="4">
                    <div className="counter-box box1 text-center">
                      <h3>
                        <CountUp end={18072} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Total Placements</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box2 text-center">
                      <h3>
                        <CountUp end={28} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>VTU Ranks 2022</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box3 text-center">
                      <h3>
                        <CountUp end={36} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Student clubs</p>
                    </div>
                  </Col>
                </Row>
                <Row className="paddingtop-2">
                  <Col sm="4">
                    <div className="counter-box box1 text-center">
                      <h3>
                        <CountUp end={265} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Regular Faculty</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box3 text-center">
                      <h3>
                        <CountUp end={73} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Adjunct Faculty</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box2 text-center">
                      <h3>
                        <CountUp end={129} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Faculty with Ph. D</p>
                    </div>
                  </Col>
                </Row>
                <Row className="paddingtop-2">
                  <Col sm="4">
                    <div className="counter-box box1 text-center">
                      <h3>
                        <CountUp end={15} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>SFR</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box2 text-center">
                      <h3>
                        <CountUp end={19} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>UG & PG Courses</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box3 text-center">
                      <h3>
                        <CountUp end={13} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Research Centers</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    );
  }
}
